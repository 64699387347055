<template>
  <div>
    <el-radio-group v-model="tabPosition">
      <el-radio-button label="left">账号信息</el-radio-button>
      <el-radio-button label="right">登录日志</el-radio-button>
    </el-radio-group>
    <div v-if="tabPosition =='left'">
      <div style="display: flex; margin-top: 20px">
        <!-- 账号信息 -->
        <div
          style="
            width: calc(50% - 10px);
            margin-right: 10px;
            background: #fff;
            border-radius: 6px;
            padding: 10px;
          "
        >
          <div class="boxTit">
            <div class="boxTitImgText">
              <img src="@/assets/personalCenter/icon_personal_account.png" />
              <div>账号信息</div>
            </div>
          </div>
          <div class="avatar">
              <el-upload
                style="width: 100%; height: 100%"
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :http-request="reviseImg"
                :show-file-list="false"
              >
                <img
                  v-if="avatarUrl"
                  :src="this.$imageAddress(avatarUrl)"
                  class="avatarImg"
                />
                <img 
                  v-else-if="gender == 1"
                  src="@/assets/personalCenter/img_head_man.png"
                />
                <img 
                  v-else
                  src="@/assets/personalCenter/img_head_women.png"
                />
              </el-upload>
          </div>
          <div class="name">
              <div class="ellipsis" v-if="!reviseName">{{ nickName }}</div>
              <el-input v-else size="mini" v-model.trim="nickName"></el-input>
              <i
                v-if="!reviseName"
                @click="reviseName = true"
                class="el-icon-edit-outline"
              ></i>
              <i
                v-else
                @click="changeNickName"
                class="el-icon-document-checked"
              ></i>
          </div>
          <div 
            style="
              width: 80%;
              text-align: center;
              margin: 12px auto;
              font-size: 18px;
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              color: #666666;
            ">
            <span>所属单位：{{ handleSchoolNameFunc(orgList) }}</span>
          </div>
          <div 
            style="
              display: flex; 
              justify-content: space-between; 
              margin-top: 24px;
            "
          >
            <div class="bottomCard">
              <div style="margin-top: 16px">{{ account }}</div>
              <div>所属账号</div>
            </div>
            <div class="bottomCard">
              <div style="margin-top: 16px; color: #0676FF;">xxx</div>
              <div>服务有效期</div>
            </div>
          </div>
       </div>
       <!-- 安全设置 -->
        <div
        style="
          width: calc(50% - 10px);
          margin-left: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px;
        "
        >
          <div class="boxTit">
            <div class="boxTitImgText">
              <img src="@/assets/personalCenter/icon_personal_secure.png" />
              <div>安全设置</div>
            </div>
          </div>
          <!-- 绑定手机 -->
          <div class="setList rightCard">
            <div class="setInfo" style="display: flex;">
              <div style="width: 40px;margin-top: 10px;margin-right: 10px;">
                <img src="@/assets/personalCenter/icon_secure_mobile.png" />
              </div>
              <div class="setInfoTit">
                <div>
                  绑定手机
                  <span class="el-icon-success successColor" v-if="!!phone">
                  已绑定 | {{ phone }}</span
                  >
                  <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
                </div>
                <div class="setInfoP">可用手机加密码登录实验室信息化管理系统</div>
              </div>
            </div>
            <el-button style="margin-right: 20px;" class="setBtn" @click="openVisible('phone')"
              >修改</el-button
            >
          </div>
           <!-- 绑定微信 -->
          <div class="setList rightCard">
            <div class="setInfo" style="display: flex;">
              <div style="width: 40px;margin-top: 10px;margin-right: 10px;">
                <img src="@/assets/personalCenter/icon_secure_wechat.png" />
              </div>
              <div class="setInfoTit">
                <div>
                  绑定微信
                  <span class="el-icon-success successColor" v-if="!!weChat">
                  已绑定 | {{ weChat }}</span
                  >
                  <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
                </div>
                <div class="setInfoP">可通过扫码认证方式登录实验室信息化管理系统</div>
              </div>
            </div>
            <el-button style="margin-right: 20px;" class="setBtn">
              修改</el-button
            >
          </div>
          <!-- 绑定邮箱 -->
          <div class="setList rightCard">
            <div class="setInfo" style="display: flex;">
              <div style="width: 40px;margin-top: 10px;margin-right: 10px;">
                <img src="@/assets/personalCenter/icon_secure_mailbox.png" />
              </div>
              <div class="setInfoTit">
                <div>
                  绑定邮箱
                  <span class="el-icon-success successColor" v-if="!!email">
                  已绑定 | {{ email }}</span
                  >
                  <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
                </div>
                <div class="setInfoP">可用邮箱加密码登录实验室信息化管理系统</div>
              </div>
            </div>
            <el-button 
              style="margin-right: 20px;" 
              class="setBtn" 
              @click="openVisible('email')"
            >修改</el-button
            >
          </div>
        </div>
      </div>
      <!-- 默认单位信息 -->
      <div 
        style="
          margin-top: 20px;
          background: #fff; 
          border-radius: 6px; 
          padding: 10px
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
          <img src="@/assets/personalCenter/icon_personal_information.png" />
          <div>默认单位信息</div>
          </div>
        </div>
        <div class="rightCard" style="width: 98%">
          <el-tag style="margin:0 20px" type="info">默认角色</el-tag>
          <span 
            style="
              color: #0676FF;
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 500;
              color: #0676FF;
            " 
            v-for="itme in roleList" 
            :key="itme.userId"
          >{{ itme.name }}、</span>
          <div class="msgInfo" style="margin: 10px 20px;padding-bottom: 20px;">
            登录实验室信息化管理系统时默认使用的角色集，同时拥有所有角色权限
          </div>
        </div>
        <div class="rightCard" style="width: 98%">
          <el-tag style="margin:0 20px" type="info">默认单位</el-tag>
          <span 
            style="color: #0676FF;
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 500;
              color: #0676FF;
            "
          >{{ handleSchoolNameFunc(orgList) }}</span>
          <div class="msgInfo" style="margin: 10px 20px;padding-bottom: 20px;">
            在使用实验室信息化管理系统中使用的单位权限默认范围
          </div>
        </div>
      </div>
    </div>
    <div v-else style="background-color: #fff;height: auto;padding-bottom: 20px;">
      <!-- 登录日志表格 -->
      <el-row style="margin-top: 20px">
          <el-table
            v-loading="loading"
            border
            ref="userHttpTable"
            :data="userHttpTable"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            style="width: 100%"
          >
            <el-table-column
              prop="gmtCreate"
              label="登录时间"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.gmtCreate | dayFilters }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createByName"
              label="用户姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="userAgentCode"
              label="登录设备"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="ip"
              label="登录地址"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="类型" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="!!scope.row.ip" type="success">成功</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px; margin-left: 20px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalSize"
          ></el-pagination>
        </el-row>
    </div>
    <!-- <el-tabs
      ref="elTab"
      v-model="activeKey"
      tab-position="left"
      style="height: 100%"
    >
      <el-tab-pane name="info" label="账号信息">
        <div class="title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="10">
            <div class="avatar">
              <el-upload
                style="width: 100%; height: 100%"
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :http-request="reviseImg"
                :show-file-list="false"
              >
                <img
                  v-if="avatarUrl"
                  :src="this.$imageAddress(avatarUrl)"
                  class="avatarImg"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="name">
              <div class="ellipsis" v-if="!reviseName">{{ nickName }}</div>
              <el-input v-else size="mini" v-model.trim="nickName"></el-input>
              <i
                v-if="!reviseName"
                @click="reviseName = true"
                class="el-icon-edit-outline"
              ></i>
              <i
                v-else
                @click="changeNickName"
                class="el-icon-document-checked"
              ></i>
            </div>
          </el-col>
          <el-col class="msgList" :span="10">
            <div><span>账号：</span>{{ account }}</div>
            <div>
              <span>所属单位：</span>
              <template v-for="itme in orgList"
                >{{ itme.sysOrgSchoolName }}、</template
              >
            </div>
            <div><span>服务有效期：</span>xxx</div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <div class="title">默认单位信息</div>
        <el-row style="margin: 20px 0 0 20px">
          <el-tag style="margin-right: 10px" type="info">默认角色</el-tag>
          <template v-for="itme in roleList">{{ itme.name }}、</template>
          <div class="msgInfo">
            登录实验室信息化管理系统时默认使用的角色集，同时拥有所有角色权限
          </div>
        </el-row>
        <el-row style="margin: 20px 0 0 20px">
          <el-tag style="margin-right: 10px" type="info">默认单位</el-tag>
          <template v-for="itme in orgList"
            >{{ itme.sysOrgSchoolName }}、</template
          >
          <div class="msgInfo">
            在使用实验室信息化管理系统中使用的单位权限默认范围
          </div>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="safe" label="安全设置">
        <div class="title">安全设置</div>
        <el-row class="setList">
          <div class="setInfo">
            <div class="setInfoTit"><i class="el-icon-lock"></i> 登录密码</div>
            <div class="setInfoP">
              安全性高的密码可以使账号更安全。建议您定期更换密码，设置一个包含字母或数字中至少长度超过6位的密码
            </div>
          </div>
          <el-button class="setBtn" @click="openVisible('password')"
            >修改</el-button
          >
        </el-row>
        <el-row class="setList">
          <div class="setInfo">
            <div class="setInfoTit">
              <i class="el-icon-phone"></i> 绑定手机
              <span class="el-icon-success successColor" v-if="!!phone">
                已绑定 {{ phone }}</span
              >
              <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
            </div>
            <div class="setInfoP">可用手机加密码登录实验室信息化管理系统</div>
          </div>
          <el-button class="setBtn" @click="openVisible('phone')"
            >修改</el-button
          >
        </el-row>
        <el-row class="setList">
          <div class="setInfo">
            <div class="setInfoTit">
              <i class="el-icon-s-custom"></i> 绑定微信
              <span class="el-icon-success successColor" v-if="!!weChat">
                已绑定 {{ weChat }}</span
              >
              <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
            </div>
            <div class="setInfoP">
              可通过扫码认证方式登录实验室信息化管理系统
            </div>
          </div>
          <el-button class="setBtn">修改</el-button>
        </el-row>
        <el-row class="setList">
          <div class="setInfo">
            <div class="setInfoTit">
              <i class="el-icon-message"></i> 绑定邮箱
              <span class="el-icon-success successColor" v-if="!!email">
                已绑定 {{ email }}</span
              >
              <span class="el-icon-remove dangerColor" v-else> 未绑定</span>
            </div>
            <div class="setInfoP">可用邮箱加密码登录实验室信息化管理系统</div>
          </div>
          <el-button class="setBtn" @click="openVisible('email')"
            >修改</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="log" label="登录日志">
        <el-row style="margin-top: 20px">
          <el-table
            v-loading="loading"
            border
            ref="userHttpTable"
            :data="userHttpTable"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            style="width: 100%"
          >
            <el-table-column
              prop="gmtCreate"
              label="登录时间"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.gmtCreate | dayFilters }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createByName"
              label="用户姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="userAgentCode"
              label="登录设备"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="ip"
              label="登录地址"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="类型" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="!!scope.row.ip" type="success">成功</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalSize"
          ></el-pagination>
        </el-row>
      </el-tab-pane>
    </el-tabs> -->
    <!-- 安全设置弹框 -->
    <!--修改密码  -->
    <el-dialog
      title="更改密码"
      :visible.sync="changVisible.password"
      width="30%"
    >
      <el-form
        ref="passwordForm"
        :model="passwordForm"
        :rules="passwordRules"
        label-width="80px"
      >
        <el-form-item label="原密码" prop="orgPassword">
          <el-input v-model="passwordForm.orgPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="passwordForm.newPassword" show-password></el-input>
        </el-form-item>
        <div class="passwordTips">密码由4-15位的阿拉伯数字或英文字母组成</div>
        <el-form-item label="确认密码" prop="repNewPassword">
          <el-input
            v-model="passwordForm.repNewPassword"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changVisible.password = false">取 消</el-button>
        <el-button type="primary" @click="changPassword('passwordForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改手机 -->
    <el-dialog
      title="更改手机号"
      :visible.sync="changVisible.phone"
      width="30%"
    >
      <el-form
        ref="phoneForm"
        :model="phoneForm"
        :rules="phoneRules"
        label-width="80px"
        hide-required-asterisk
      >
        <el-form-item label="原手机号">
          <el-input v-model="phone" disabled></el-input>
        </el-form-item>
        <el-form-item label="新手机号" prop="phone">
          <el-input
            v-model="phoneForm.phone"
            clearable
            placeholder="请输入手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changVisible.phone = false">取 消</el-button>
        <el-button type="primary" @click="changPhone('phoneForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改微信 -->
    <!-- 修改邮箱 -->
    <el-dialog title="更改邮箱" :visible.sync="changVisible.email" width="30%">
      <el-form
        ref="emailForm"
        :model="emailForm"
        :rules="emailRules"
        label-width="80px"
        hide-required-asterisk
      >
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="emailForm.email" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changVisible.email = false">取 消</el-button>
        <el-button type="primary" @click="changEmail('emailForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getUserDetails,
  reviseUserData,
  getUserHttpEvent,
  revisePassword,
} from '@/api/account/accountApi.js'
import { fileSingle } from '@/api/fileUpload/fileUpload.js'
import { throttle } from '@/utils/util'
import dayjs from 'dayjs'
export default {
  data() {
    //校验规则
    const checkOrgPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入原密码'))
      } else {
        if (this.rulesNum == 1) {
          return callback(new Error('原密码错误'))
        } else {
          callback()
        }
      }
    }
    const checkNewPassword = (rule, value, callback) => {
      if (
        !value ||
        value.length < 4 ||
        value.length > 15 ||
        /[^a-zA-Z0-9]/g.test(value)
      ) {
        callback(new Error('请按规则输入密码'))
      } else {
        callback()
      }
    }
    const checkRepNewPassword = (rule, value, callback) => {
      if (value != this.passwordForm.newPassword) {
        callback(new Error('密码不一致'))
      } else {
        callback()
      }
    }
    return {
      activeKey: 'safe',
      activeKeyList: ['info', 'safe', 'log'],
      allData: {},
      // 基础信息
      nickName: '',
      reviseName: false,
      account: '',
      avatarUrl: '',
      roleList: [],
      orgList: [],
      // 安全设置
      email: '',
      phone: '',
      weChat: '',
      // 弹框
      changVisible: {
        password: false,
        phone: false,
        weChat: false,
        email: false,
      },
      // 密码
      passwordForm: {
        orgPassword: '',
        newPassword: '',
        repNewPassword: '',
      },
      passwordRules: {
        orgPassword: [{ validator: checkOrgPassword, trigger: 'blur' }],
        newPassword: [{ validator: checkNewPassword, trigger: 'blur' }],
        repNewPassword: [{ validator: checkRepNewPassword, trigger: 'blur' }],
      },
      // 手机号
      phoneForm: {
        phone: '',
      },
      phoneRules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change'],
          },
        ],
      },
      // 微信
      // 邮箱
      emailForm: {
        email: '',
      },
      emailRules: {
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur'],
          },
        ],
      },
      // 登录日志
      //遮罩层
      loading: false,
      userHttpTable: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },

      tabPosition: 'left',
      gender: 1
    }
  },
  mounted() {
    this.getUserInfo()
    this.getUserHttpEventFunc()

    // this.$refs.elTab.$el.addEventListener('mousewheel', this.handleClick)
    document.querySelector('body').setAttribute('style', 'background: #F5F6FA;')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 滚轮滚动
    handleClick: throttle(function (e) {
      // let num = this.activeKeyList.indexOf(this.activeKey) + (e.deltaY > 0 ? 1 : -1)
      // num = (num + this.activeKeyList.length) % this.activeKeyList.length
      // this.activeKey = this.activeKeyList[num]
      const key = this.activeKeyList
      this.activeKey =
        key[
          (key.indexOf(this.activeKey) + Math.sign(e.deltaY) + key.length) %
            key.length
        ]
    }, 500),
    // 获取用户信息
    getUserInfo() {
      getUserDetails()
        .then((res) => {
          console.log('获取用户信息', res)
          if (res.success) {
            // this.allData = JSON.parse(JSON.stringify(res.data))
            // 基础信息
            this.nickName = res.data.nickName
            this.account = res.data.account
            this.avatarUrl = res.data.avatarUrl
            this.roleList = res.data.roleList
            this.orgList = res.data.orgList
            // 安全设置
            this.email = res.data.email
            this.phone = res.data.phone
            this.gender = res.data.gender
          }
        })
        .catch((err) => {})
    },
    // 修改用户信息
    setUserInfo(data, callback) {
      reviseUserData(data)
        .then((res) => {
          if (res.success) {
            this.$message.success('保存成功')
            callback && callback()
            // this.getUserInfo()
          }
        })
        .catch((err) => {
          this.$message.success('保存失败:', res.msg)
        })
    },
    // 上传头像
    reviseImg(file) {
      let fd = new FormData()
      // fd.append('code', 'default')
      fd.append('code', 'user_img')
      fd.append('file', file.file)
      fileSingle(fd)
        .then((res) => {
          console.log('上传图片', res)
          if (res.success) {
            // 上传成功 获取链接替换avatarUrl
            this.avatarUrl = res.data.url
            this.setUserInfo({ avatarUrl: this.avatarUrl })
          }else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 修改名字
    changeNickName() {
      console.log(' val', this.nickName)
      this.reviseName = false
      this.setUserInfo({ nickName: this.nickName })
    },
    //安全
    // 打开弹框
    openVisible(key) {
      switch (key) {
        case 'password':
          this.passwordForm.orgPassword = ''
          this.passwordForm.newPassword = ''
          this.passwordForm.repNewPassword = ''
          break
        case 'phone':
          this.phoneForm.phone = ''
          break
        case 'weChat':
          this.changVisible.weChat = true
          break
        case 'email':
          this.emailForm.email = ''
          break
      }
      this.changVisible[key] = true
      // 清除正则报错
      this.$nextTick(() => {
        this.$refs[`${key}Form`].clearValidate()
      })
    },
    // 修改密码
    changPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let passwordParam = {
            account: this.account,
            newPassword: this.passwordForm.newPassword,
            password: this.passwordForm.orgPassword,
          }
          revisePassword(passwordParam).then((res) => {
            console.log('修改密码', res)
            if (res.success) {
              this.changVisible.password = false
              this.$message.success('密码修改成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 修改手机号
    changPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setUserInfo({ phone: this.phoneForm.phone }, () => {
            this.phone = JSON.parse(JSON.stringify(this.phoneForm.phone))
            this.changVisible.phone = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 修改微信
    // 修改邮箱
    changEmail(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setUserInfo({ email: this.emailForm.email }, () => {
            this.email = JSON.parse(JSON.stringify(this.emailForm.email))
            this.changVisible.email = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 登录日志
    getUserHttpEventFunc() {
      this.loading = true
      getUserHttpEvent(this.page.pageIndex, this.page.pageSize)
        .then((res) => {
          this.loading = false
          console.log('登录日志', res)
          if (res.success) {
            let { data, pageIndex, total } = res
            this.userHttpTable = data
            this.page.pageIndex = pageIndex
            this.page.totalSize = total
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getUserHttpEventFunc()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.getUserHttpEventFunc()
    },
    // 处理所属单位
    handleSchoolNameFunc(orgList){
      let arr = []
      orgList.forEach(it=> {
        if(!arr.includes(it.sysOrgSchoolName)){
          arr.push(it.sysOrgSchoolName)
        }
      })
      return arr.join(", ")
    }
  },
  filters: {
    dayFilters(val) {
      return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 26px;
  margin: 20px 0;
}
// 头像
.avatar {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 20px;
  margin-top: 0;
  .avatarImg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.name {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  i {
    cursor: pointer;
    margin: 0 4px;
    &:hover {
      color: #409eff;
    }
  }
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
}
.msgList {
  div {
    margin: 20px;
    font-size: 18px;
  }
  span {
    width: 150px;
    display: inline-block;
  }
}
.msgInfo {
  color: #ccc;
}
// 上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin: 25px auto 18px;
}
// 安全设置
.setList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px;
  margin-right: 20px;
  .setBtn {
    width: 70px;
    height: 40px;
  }
  .setInfo {
    width: calc(100% - 70px);
    display: inline-block;
    height: 80px;
    > div {
      height: 40px;
      width: 100%;
    }
    .setInfoTit {
      font-size: 20px;
      line-height: 40px;
      font-weight: bold;
      span {
        margin-left: 50px;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .setInfoP {
      font-size: 16px;
      line-height: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
    }
  }
}
.successColor {
  color: #0676FF;
}
.dangerColor {
  color: #f56c6c;
}
.passwordTips {
  font-size: 12px;
  color: #2e9aff;
  float: right;
  position: relative;
  margin-top: -18px;
  margin-bottom: 5px;
}
.boxTit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
  box-sizing: border-box;
}
.boxTitImgText {
  display: flex;
  align-items: center;
  margin: 0;
  img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
  div {
    height: 22px;
    line-height: 22px;
  }
}
.bottomCard {
  width: 48%;
  height: 84px;
  background: #F8FAFB;
  border-radius: 6px;
  text-align: center;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #586782;
}
.rightCard {
  width: 92%;
  margin: 18px auto;
  padding-top: 20px;
  background: #F8FAFB;
  border-radius: 6px;
}
</style>
